<template>
    <div>    
        <v-divider></v-divider>  
        <mbs-page-tab 
            :items="PageTaps"/>   
        <v-divider></v-divider>  
        <v-divider></v-divider>  
        <v-divider></v-divider>  
        <v-container grid-list-xl>  
            <v-layout class="mt-1" align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <v-card :min-height="vsh/2" >   
                        <mbs-item-manager  v-model="input_table"
                            :table_title="'Manage Permission Groups'"
                            :table_title_icon="'mdi-shield-lock'"
                            :add_vertical_lines="true"
                            :row_click="true"
                            :show_select="false"
                            :height="null"
                            :fixed_header="false" 
                            :table_tabs="TABLE_TABS"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="DATA.FIELDS.user_groups" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="CompanyUserGroupsData"  
                            :is_joined="false"  
                            :path_item="PATH_ITEM"  
                            :select_items="DataSource"   

                            :table_actions="TableActions" 
                            @ON_ITEM_SELECTED="selected_items=$event"
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                            @ADDED="ADDED"
                            @EDITED="EDITED"
                            @DELETED="DELETED"
                        />  
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  

        <!-- //DIALOGS -->
        <div>  
            <!-- CHILD ITEMS DIALOG -->
            <v-dialog fullscreen v-model="dialog_child_items"   persistent max-width="900px"> 
                <v-card :style="'overflow: scroll; '" tile height="100%" color="b2">
                    <v-layout column fill-height> 
                        <template v-if="SELECTED_ITEM"> 
                            <v-card-title class="primary white--text" dark>
                                <v-btn  @click="dialog_child_items=false" color="white" icon="" class="ml-2 mr-5"><v-icon size="" >mdi-arrow-left</v-icon></v-btn>
                                <span class="headline">{{SELECTED_ITEM?SELECTED_ITEM.code:''}} : {{MBS.actions.TEXT_UP(SELECTED_ITEM?SELECTED_ITEM.name:'')}} : {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} </span>
                                <v-spacer></v-spacer>   
                                <v-btn  @click="dialog_child_items=false" color="white" icon="" class="ml-2"><v-icon size="" class="pr-1">mdi-close</v-icon></v-btn> 
                            </v-card-title>  
                            <v-card-text >   
                                <v-container grid-list-lg>
                                    <br>
                                    <v-card  > 
                                        <v-card-title>
                                            ACTION {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} 
                                            <v-spacer></v-spacer> 
                                            <v-text-field
                                                v-model="search"
                                                append-icon="search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <div v-if="ITEM_ACTION_PERMISSIONS">
                                            <v-data-table
                                                :headers="HEADERS('action')"
                                                :items="ITEM_ACTION_PERMISSIONS"
                                                :search="search" >

                                                <template v-slot:top> 
                                                </template>  
                                                <template v-slot:item.read_permission="{item}" >  
                                                    <div class="pr-5">
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'read_permission',true)" 
                                                            icon="" v-if="item.my_permission?item.my_permission.read_permission:false">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                        </v-btn>
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'read_permission',false)" 
                                                            v-else icon="">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                        </v-btn> 
                                                    </div> 
                                                </template>
                                                <template v-slot:item.write_permission="{item}" >  
                                                    <div class="pr-5">
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'write_permission',true)" 
                                                            icon="" v-if="item.my_permission?item.my_permission.write_permission:false">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                        </v-btn>
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'write_permission',false)" 
                                                            v-else icon="">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                        </v-btn> 
                                                    </div> 
                                                </template>
                                                <template v-slot:item.delete_permission="{item}" > 
                                                    <div class="pr-5">
                                                        <v-btn icon="" @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'delete_permission',true)" 
                                                            v-if="item.my_permission?item.my_permission.delete_permission:false">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                        </v-btn>
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'delete_permission',false)" 
                                                            v-else icon="">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                        </v-btn> 
                                                    </div> 
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                                        <v-icon>warning</v-icon>
                                                        Empty
                                                    </v-layout>
                                                </template>
                                            </v-data-table>    
                                        </div>
                                        <v-layout v-else justify-center align-center class="py-15">
                                            <v-icon>warning</v-icon> 
                                            <div class="pl-2 pt-1">No data found</div>
                                        </v-layout>
                                    </v-card> 
                                    <br>
                                    <v-card   > 
                                        <v-card-title>
                                            OPTION {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} 
                                            <v-spacer></v-spacer> 
                                            <v-text-field
                                                v-model="search"
                                                append-icon="search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <div v-if="ITEM_OPTION_PERMISSIONS">
                                            <v-data-table
                                                :headers="HEADERS('page')"
                                                :items="ITEM_OPTION_PERMISSIONS"
                                                :search="search" >

                                                <template v-slot:top> 
                                                </template>  
                                                <template v-slot:item.view_permission="{item}" >  
                                                    <div class="pr-5">
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'view_permission',true)" 
                                                            icon="" v-if="item.my_permission?item.my_permission.view_permission:false">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                        </v-btn>
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'view_permission',false)"  
                                                            v-else icon="">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                        </v-btn> 
                                                    </div> 
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                                        <v-icon>warning</v-icon>
                                                        Empty
                                                    </v-layout>
                                                </template>
                                            </v-data-table>  
                                        </div>
                                        <v-layout v-else justify-center align-center class="py-15">
                                        <v-icon>warning</v-icon> 
                                        <div class="pl-2 pt-1">No data found</div>
                                        </v-layout>
                                    </v-card>
                                    <br>
                                    <v-card   > 
                                        <v-card-title>
                                            PAGE {{MBS.actions.TEXT_UP(NAME_CHILD_ITEMS)}} 
                                            <v-spacer></v-spacer> 
                                            <v-text-field
                                                v-model="search"
                                                append-icon="search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <div v-if="ITEM_PAGE_PERMISSIONS">
                                            <v-data-table
                                                :headers="HEADERS('page')"
                                                :items="ITEM_PAGE_PERMISSIONS"
                                                :search="search" >

                                                <template v-slot:top> 
                                                </template>  
                                                <template v-slot:item.view_permission="{item}" >  
                                                    <div class="pr-5">
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'view_permission',true)" 
                                                            icon="" v-if="item.my_permission?item.my_permission.view_permission:false">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxMarked')}}</v-icon>
                                                        </v-btn>
                                                        <v-btn @click="ADD_ITEM_PERMISSION(SELECTED_ITEM,item,'view_permission',false)"  
                                                            v-else icon="">
                                                            <v-icon >{{MBS.actions.icon('mdiCheckboxBlankOutline ')}}</v-icon>
                                                        </v-btn> 
                                                    </div> 
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-layout align-center="" justify-center="" class="primary lighten-5 pa-5">
                                                        <v-icon>warning</v-icon>
                                                        Empty
                                                    </v-layout>
                                                </template>
                                            </v-data-table>  
                                        </div>
                                        <v-layout v-else justify-center align-center class="py-15">
                                        <v-icon>warning</v-icon> 
                                        <div class="pl-2 pt-1">No data found</div>
                                        </v-layout>
                                    </v-card> 
                                </v-container>  
                            </v-card-text>    
                        </template>
                        <template v-else>
                            <v-card>
                                <v-layout justify-center align-center class="pa-5">
                                    No selected item
                                </v-layout>
                            </v-card>
                        </template>
                        <v-spacer></v-spacer>
                        <div >
                            <v-divider></v-divider>
                            <v-layout justify-center="" class="px-5 py-5">  
                                <v-spacer></v-spacer>
                                <v-btn outlined @click="dialog_child_items=false" color="primary" >CLOSE</v-btn> 
                            </v-layout>   
                        </div> 
                    </v-layout>
                </v-card>
            </v-dialog>
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex" 
import TriageVue from '../../hospital/Triage.vue'
    let PAGE_NAME = DATA.ITEMS.COMPANY_USER_GROUPS.values
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                CHILD:false,
                SELECTED_ITEM:null,
                NAME_ITEMS:DATA.ITEMS.COMPANY_USER_GROUPS.names,
                NAME_ITEM:DATA.ITEMS.COMPANY_USER_GROUPS.name,
                VALUE_ITEMS:DATA.ITEMS.COMPANY_USER_GROUPS.values,
                VALUE_ITEM:DATA.ITEMS.COMPANY_USER_GROUPS.value,

                NAME_CHILD_ITEMS:DATA.ITEMS.PERMISSIONS.names,
                NAME_CHILD_ITEM:DATA.ITEMS.PERMISSIONS.name,
                VALUE_CHILD_ITEMS:DATA.ITEMS.PERMISSIONS.values,
                VALUE_CHILD_ITEM:DATA.ITEMS.PERMISSIONS.value,

                DATA:DATA, 
                ADDING_ITEM:null,

                menuTableSet:false, 
                menuTableSetChild:false,
                dialog_add_item:false,
                dialog_edit_item:false, 
                dialog_child_items:false,
                inputCode:null,
                input:{},
                input_table:{show_select:false},
                search:'',  
                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},  
                ],
                FIELD_CHILD_ITEMS:[
                    {type:"select",name:"permission",value:"permission_code",label:"Select Permission",required:true,sm:12,md:12,select:{
                        name:'permissions',
                        value:'code',
                        text:'name'
                    }}, 
                ],
                 
                HEADER_CHILD_ITEMS:[ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"name",value:'name',show:true},   
                    {id:0,name:"code",value:'code',show:true},   

                    {id:0,name:"create",value:'created_at',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},  
                    {id:0,name:"action",align:"right",show:true}, 
                ],
                HEADER_PAGE_PERMISSION:[ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"name",value:'name',show:true},   
                    {id:0,name:"access",value:'access_type',show:true},   
                    {id:0,name:"view",value:"view_permission",align:"right",show:true},        
                ],    
                HEADER_ACTION_PERMISSION:[ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"name",value:'name',show:true},    
                    {id:0,name:"access",value:'access_type',show:true},    
                    {id:0,name:"read",value:"read_permission",align:"right",show:true},    
                    {id:0,name:"write",value:"write_permission",align:"right",show:true},    
                    {id:0,name:"delete",value:"delete_permission",align:"right",show:true},      
                ],    
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:this.items,
                    color:'black',
                    height:"250",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            PageTaps(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("security",2,true) 
                return thisLinks
            },
             
            PATH_ITEM(){ 
                let link = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_user_groups,true) 
                return link
            }, 

            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Users: state=> state.users[(DATA.ITEMS.USERS.values).toUpperCase()],
                // JoinedUsers: state=> state.join[(DATA.ITEMS.USERS.values).toUpperCase()], 
                CompanyUsers: state=> state.items[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],
                // JoinedCompanyUsers: state=> state.join[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()], 
                CompanyUserGroups: state=> state.items[(DATA.ITEMS.COMPANY_USER_GROUPS.values).toUpperCase()],
                JoinedCompanyUserGroups: state=> state.join[(DATA.ITEMS.COMPANY_USER_GROUPS.values).toUpperCase()],  
                Cards: state=> state.items[(DATA.ITEMS.CARDS.values).toUpperCase()],
                JoinedCards: state=> state.join[(DATA.ITEMS.CARDS.values).toUpperCase()],  
            }),

            CompanyUserGroupsData(){
                let CompanyUserGroups = this.CompanyUserGroups
                let JoinedCompanyUserGroups = this.JoinedCompanyUserGroups
                let items = JoinedCompanyUserGroups?JoinedCompanyUserGroups:CompanyUserGroups 
                return items
            },

            TableActions(){
                let selected_items = this.selected_items
                let input_table = this.input_table
                let show_select = input_table?input_table.show_select:false
                let main_action = [
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",btn:true,icon:'mdi-lock-open-plus',action:"add_item",color:"secondary", outlined:false,  text:"Add Permission User Group "},    
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ] 
                return  main_action
            },
            TABLE_TABS(){
                return [
                    {
                        name:"All",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item["number_quantity"])>0?true:false
                            return true
                        },
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }
                    }}, 
                    {
                        name:"Defaults",show_if:(this_,item)=>{  
                            return item.is_default
                        },
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}
                        }
                    }},  
                    {
                        name:"Customized",show_if:(this_,item)=>{  
                            return  !item.is_default
                        },
                        indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                ]
            },
            FILTERS(){ 
                return [ 
                    {
                        type:"autocomplete",value:"department_code",prepend_inner_icon:'mdi-format-list-group',clearable:true,filled:false,multiple:true
                        ,chips:true,deletable_chips:true,label:"Department",
                        select:{
                            name:'ItemDepartments',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"category_code",prepend_inner_icon:'mdi-shape-plus',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Category",
                        select:{
                            name:'ItemCategories',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"manufacturer_code",prepend_inner_icon:'mdi-factory',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Manufacturer",
                        select:{
                            name:'Manufacturers',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"item_code",search_value:"code",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Barcode",
                        select:{
                            name:'Items',
                            value:'code',
                            text:'code'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Item Name",
                        select:{
                            name:'Items',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    }, 
                    
                        
                ]
            }, 
            HEADER_ITEMS(){ 
                let tiers = this.AllPricingTiers
                let headers = [
                    {id:1,name:"General",title:true}, 
                    // {id:0,name:"",value:'icon_image',icon:"mdi-folder-lock",color:"",background_color:"2",size:35,tile:true,cellClass:' ',class:' b ',align:'',show:true},   
                    {id:0,name:"No.",value:'no',show:TriageVue},  
                    {id:0,name:"name",value:'name',show:true},  
                    {id:0,name:"code",value:'code',show:false},   
                    {id:0,name:"Type",value:'default_name',show:true},  
                    // {id:0,name:"Permissions",value:'permissions_size',show:true},  
                    {id:0,name:"Description",value:'description',show:true},   
                    {id:0,name:"User",value:'created_user_name',show:true},    
                    {id:0,name:"create",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at_',show:true}, 
                    {id:0,name:"action",align:"center",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Manage Permission",action:"manage_permission",icon:"check_box"},
                        {name:"Delete "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"},
                    ]}, 
                ] 
                return headers 
            }, 
            
            DataSource(){ 
                let Users = this.JoinedUsers
                let UserGroups = this.CompanyUserGroupsData 
                let Cards = this.CardsData 
                return  {   
                    Users: Users, 
                    UserGroups: UserGroups, 
                    Cards: Cards, 
                }
            }, 


            DATA_ITEMS(){ 
                return this.$store.getters.getCompanyUserGroups
            }, 
            DATA_CHILD_ITEMS(){
                return this.$store.getters.getPermissions
            },   
            SELECT_ITEMS(){ 
                let item = this.DATA_CHILD_ITEMS 
                return {
                    permission:item
                }
            }, 
            JOINED_DATA_ITEMS(){
                let DATA_ITEMS = this.DATA_ITEMS
                let join = this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:this.VALUE_ITEMS
                }) 
                return join
            },
            JOINED_CHILD_DATA_ITEMS(){
                let DATA_ITEMS = this.DATA_CHILD_ITEMS
                let join = this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:'permissions'
                }) 
                return join
            },
            SELECTED_DATA_ITEM(){
                let item = this.DATA_ITEMS
                let SELECTED_ITEM = this.SELECTED_ITEM 
                if(!item){return null}
                if(!SELECTED_ITEM){return null}

                let filtered_item = item.find(child=>{
                    return child.code == SELECTED_ITEM.code
                }) 
                return filtered_item
            }, 
            ITEM_PERMISSIONS(){
                // let item = this.SELECTED_ITEM
                let item = this.SELECTED_DATA_ITEM
                if(!item){return null}
                let object_permissions = item.permissions
                let permissions = this.MBS.actions.OBJECT_ITEMS(object_permissions)
                return permissions
            },
            JOINED_ITEM_PERMISSIONS(){
                let DATA_ITEMS = this.ITEM_PERMISSIONS
                let join = this.MBS.actions.JOIN({
                    items:DATA_ITEMS,
                    name:this.VALUE_CHILD_ITEMS
                }) 
                if(!join){return null}
                let filtered = join.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.access_type) == this.MBS.actions.TEXT_UP('company')
                })  
                let list = [...filtered]
                list.sort(this.MBS.actions.dynamicSort("name",false))   
                return list 
            },
            ITEM_PAGE_PERMISSIONS(){
                let DATA_ITEMS = this.JOINED_ITEM_PERMISSIONS 
                if(!DATA_ITEMS){return null}
                let filtered = DATA_ITEMS.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.permission_type) == this.MBS.actions.TEXT_UP('page')
                })  
                return filtered
            },
            ITEM_ACTION_PERMISSIONS(){
                let DATA_ITEMS = this.JOINED_ITEM_PERMISSIONS
                if(!DATA_ITEMS){return null}
                let filtered = DATA_ITEMS.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.permission_type) == this.MBS.actions.TEXT_UP('action')
                })  
                return filtered
            }, 
            ITEM_OPTION_PERMISSIONS(){
                let DATA_ITEMS = this.JOINED_ITEM_PERMISSIONS
                if(!DATA_ITEMS){return null}
                let filtered = DATA_ITEMS.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.permission_type) == this.MBS.actions.TEXT_UP('option')
                })  
                return filtered
            }, 
        },
        methods: { 
            HEADERS(type){
                try {
                    let hds = null
                    if (type === this.VALUE_ITEMS) {
                        hds = this.HEADER_ITEMS
                    }else if (type === 'excel'){
                        hds = this.HEADER_ITEMS2
                    } else if (type === this.VALUE_CHILD_ITEMS){
                        hds = this.HEADER_CHILD_ITEMS
                    } else if (type === 'page'){
                        hds = this.HEADER_PAGE_PERMISSION
                    } else if (type === 'action'){
                        hds = this.HEADER_ACTION_PERMISSION
                    } 
                    let headers = this.MBS.actions.CREATE_TABLE_HEADER(hds) 
                    return headers
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'HEADERS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {  
                     console.log(action,'action');
                    if (action == "add_action_item") {
                        this.ADD_ITEM()
                    }else if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("User Groups Data","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ITEM_ACTION(action,item,option){
                try {      
                    if (action == "on_row_clicked") {
                         
                    }else if (action == "manage_permission") {
                        this.SHOW_CHILD_ITEM(item)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADDED(res){
                try { 
                    this.RELOAD_ITEMS()
                    //a 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDITED(res){
                try {
                    console.log(res,'--------------edited');
                    // this.RELOAD_ITEMS()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETED(res){
                try {
                    this.RELOAD_ITEMS()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },

            
            ADD_ITEM_PERMISSION(item,permission,permission_type){
                try { 
                    let user_group_key = item?item.key:null
                    let permission_code = item?permission.code:null
                    let permission_key = item?permission.key:null
                    let my_permission = item?permission.my_permission:null
                    let my_permission_key = my_permission?my_permission.key:null

                    
                    if (!user_group_key) {
                        let mbs_text = this.MBS.text.error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }else if (!permission_key ) {
                        let mbs_text = this.MBS.text.error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }else if (!permission_type) {
                        let mbs_text = this.MBS.text.error_0
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    } else { 
                        let view_permission = my_permission?my_permission.view_permission :false
                        let read_permission  = my_permission?my_permission.read_permission :false
                        let write_permission  = my_permission?my_permission.write_permission :false
                        let delete_permission  = my_permission?my_permission.delete_permission :false
                        let data = {}
                        if (permission_type=='view_permission') {
                            data = {view_permission:!view_permission}
                        }if (permission_type=='read_permission') {
                            data = {read_permission:!read_permission}
                        }if (permission_type=='write_permission') {
                            data = {write_permission:!write_permission}
                        }if (permission_type=='delete_permission') {
                            data = {delete_permission:!delete_permission}
                        }  
                        let  ACTION_TYPE = null
                        if (my_permission_key) {
                            ACTION_TYPE= "=UPDATE-PERMISSION"
                        } else {
                            ACTION_TYPE= "=ADD-PERMISSION"
                        }  
                        let submit_details = {
                            ...data,
                            user_group_key:user_group_key,
                            // permission_code:permission_code,
                            // permission_key:permission_key,
                            
                            permission_code:null,
                            permission_key:null,
                            my_permission_key:my_permission_key
                        }

                        if (permission_code) {
                            submit_details.permission_code = permission_code
                        } else if(permission_key){
                            submit_details.permission_key = permission_key
                        }
                        // console.log(submit_details,'submit_details.....');
                        this.input.check = true
                        let mbs_text = this.MBS.text.update_item("Permission")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+ACTION_TYPE, 
                                data:submit_details
                            }
                        })  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM_PERMISSION',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            SHOW_CHILD_ITEM(item){
                try {  
                    this.SELECTED_ITEM = item 
                    this.dialog_child_items = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHOW_CHILD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => { 
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:action.child?this.PATH_CHILD_ITEM:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);  
                                }   
                            } else { 
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=EDIT-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.loading = true  
                                        this.$store.dispatch("fi_update",{
                                            action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                            path:action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/",
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else {
                                    
                                }
                            } 
                        }  
                        if (action.code ===PAGE_NAME+'=ADD-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_add_item = false 
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=ADD-CHILD-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_add_child_item = false 
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=EDIT-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_edit_item = false 
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=EDIT-CHILD-ITEM-SUCCESS') {
                            if (action.YES) {
                                this.dialog_edit_child_item= false 
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=ADD-PERMISSION') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false  
                                    setTimeout(() => { 
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_CHILD_ITEM,
                                            path:this.PATH_ITEM+action.data.user_group_key+"/permissions/",
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);  
                                }   
                            } else { 
                            }
                        }
                        if (action.code ===PAGE_NAME+'=UPDATE-PERMISSION') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.loading = true  
                                        this.$store.dispatch("fi_update",{
                                            action:this.VALUE_CHILD_ITEM,
                                            path:this.PATH_ITEM+action.data.user_group_key+"/permissions/"+action.data.my_permission_key,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else {
                                    
                                }
                            } 
                        }   

                        if (action.code ===PAGE_NAME+'=CONFIRM-DELETE-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = true 
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm deleting this "+action.child?this.NAME_CHILD_ITEM:this.NAME_ITEM,btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=DELETE-ITEM",
                                            }
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } else {
                                    
                                }
                            }  
                        }  
                        if (action.code ===PAGE_NAME+"=CONFIRM-DELETE-ALL-ITEMS") {
                            if (this.input.check) {  
                                this.input.check = false
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.check = true 
                                        this.MBS.actions.dialogInput({
                                            show:true,
                                            fixed:true,
                                            title:"CONFIRM",
                                            text:"Enter your password to confirm deleting all "+this.MBS.actions.TEXT_UP0(this.NAME_ITEMS)+". This action can not be reversed",btnNo:"CANCEL",
                                            btnYes:"CONFIRM",
                                            field:[
                                                {field:"password",label:'Password',type:'password'}
                                            ],
                                            action:{
                                                ...action,
                                                code:PAGE_NAME+"=DELETE-ALL-ITEMS",
                                            }
                                        })   
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } else { 
                                }
                            }   
                        } 
                        
                        if (action.code ===PAGE_NAME+'=DELETE-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = false
                                        let password = this.MBS.crypt.encrypt(action.password) 
                                        //TODO..............
                                        if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                        this.$store.dispatch("fi_delete",{
                                                action:action.child?this.VALUE_CHILD_ITEM:this.VALUE_ITEM,
                                                path:action.child?this.PATH_CHILD_ITEM+action.data.key+"/":this.PATH_ITEM+action.data.key+"/"
                                            })  
                                        } 
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        }
                        if (action.code ===PAGE_NAME+'=DELETE-CHILD-ITEM') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.check = false
                                        let password = this.MBS.crypt.encrypt(action.password)
                                        //TODO..............
                                        if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                        this.$store.dispatch("fi_delete",{
                                                action:this.VALUE_CHILD_ITEM,
                                                path:this.PATH_CHILD_ITEM+action.data.key+"/"
                                            })  
                                        } 
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        } 
                        if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEMS') {
                            if (this.input.check) {  
                                this.input.check = false 
                                if (action.YES) {
                                    setTimeout(() => {
                                        this.input.check = false
                                        let password = this.MBS.crypt.encrypt(action.password)
                                        //TODO..............
                                        if (password!==this.ud.p_uid) { 
                                            this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                        } else {
                                            this.$store.dispatch("fi_delete",{
                                                action:this.VALUE_ITEMS,
                                                path:this.PATH_ITEM 
                                            })
                                        }  
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }  
                        } 
                        if (action.code===PAGE_NAME+'=DELETE-IMAGE') { 
                            if (action.YES) {
                                if (this.input.check) {
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.$store.dispatch("fi_deleteImage",{
                                            path:this.PATH_ITEM+action.key,
                                            link:action.image.link,
                                            action:this.VALUE_ITEM_IMAGE,
                                        })  
                                    }, this.MBS.data.ACTION_REFRESH_TIME); 
                                } 
                            } else { 
                            }
                        }

                        if (action.code ===PAGE_NAME+'=SELECT-EXCEL') {
                            if (action.YES) {
                                this.SELECT_EXCEL()   
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=REMOVE-EXCEL-ITEM') {
                            if (action.YES) {
                                let item =action.item 
                                let items = this.EXCEL_DATA_ITEMS
                                this.EXCEL_DATA_ITEMS = items.filter(cs=>{
                                    return cs.code != item.code
                                })  
                            } else { 
                            }
                        } 

                        if (action.code ===PAGE_NAME+'=UPLOAD-REPLACE-EXCEL') {
                            if (this.input.check) {  
                                this.input.check = false  
                                if (action.YES) {
                                    setTimeout(() => { 
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_set",{
                                            action:this.VALUE_ITEMS,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us,
                                            array:true
                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);    
                                } else { 
                                }
                            }   
                        }

                        if (action.code ===PAGE_NAME+'=AUTO-GENERATE-CODE') { 
                            if (action.YES) {
                                this.EXCEL_DATA_ITEMS= this.MBS.actions.CODE_AUTO_GENERATE(this.EXCEL_DATA_ITEMS) 
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=AUTO-SET-CODE') { 
                            if (action.YES) {
                                this.inputCode = (this.MBS.actions.CODE_GET_LAST(action.data)+1)
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }
                        }
                        if (action.code===PAGE_NAME+":DELETE-MESSAGE") {
                            if (action.YES) {
                                this.$store.dispatch("fi_delete",{
                                    action:"Message",
                                    path:"MBS/MESSAGES/"+action.key
                                })  
                            } else { 
                            }
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        // console.log(ps,'ps............');

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                            let addingItem = ps.find(process=>{
                                return process.name == 'adding/'+this.VALUE_ITEM
                            })
                            let addingChildItem = ps.find(process=>{
                                return process.name == 'adding/'+this.VALUE_CHILD_ITEM
                            })
                            let updatingItem = ps.find(process=>{
                                return process.name == 'updating/'+this.VALUE_ITEM
                            })  
                            let updatingChildItem = ps.find(process=>{
                                return process.name == 'updating/'+this.VALUE_CHILD_ITEM
                            })  
                            let deletingItem = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEM
                            })
                            let deletingChildItem = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_CHILD_ITEM
                            })
                            let deletingAllItems = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_ITEMS
                            })
                            let deletingAllChildItems = ps.find(process=>{
                                return process.name == 'deleting/'+this.VALUE_CHILD_ITEMS
                            })
                             

                            if (gettingItems) {
                                this.PAGE_LOAD =false 
                            }else{
                                this.PAGE_LOAD =true
                            }

                           

                            if (addingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  
                            if (addingChildItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (updatingItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  
                            if (updatingChildItem) {
                                this.MBS.actions.progressDialog({
                                    show:true,
                                    fixed:false,
                                    title:"Updating"
                                }) 
                                this.inputLoading = true
                            }else{ 
                            }  

                            if (deletingItem) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  
                            if (deletingChildItem) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:false,
                                    title:"Deleting..."
                                }) 
                            }else{ 
                            }  

                            if (deletingAllItems) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:true,
                                    title:"Deleting all..."
                                }) 
                            }else{ 
                            }
                            if (deletingAllChildItems) {
                               this.MBS.actions.progressDialog({
                                    show:true, 
                                    fixed:true,
                                    title:"Deleting all..."
                                }) 
                            }else{ 
                            }

                             
                        }else{
                            this.PAGE_LOAD =true
                        }

                        //ADD ITEM RESPONSE
                        if (res.res==='adding/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.add_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.add_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.add_item_success(this.NAME_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_add_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                            
                        }else if (res.res==='adding/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.add_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.add_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.add_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }
                        if (res.res==='adding/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.add_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_add_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                            
                        }else if (res.res==='adding/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.add_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }

                        if (res.res==='updating/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_edit_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                        if (res.res==='updating/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            }) 
                            this.input.loading = false
                            this.dialog_edit_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='updating/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 

                        
                        if (res.res==='deleting/'+this.VALUE_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEM).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEM).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEM).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 
                        if (res.res==='deleting/'+this.VALUE_CHILD_ITEM+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_CHILD_ITEM).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_CHILD_ITEM+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_CHILD_ITEM).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 

                        if (res.res==='deleting/'+this.VALUE_ITEMS+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEMS).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEMS).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/'+this.VALUE_ITEMS+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEMS).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEMS).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        } 


                        if (res.res==='deleting/image/'+this.VALUE_ITEM_IMAGE+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).title,
                                text:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).text,
                                btnYes:this.MBS.text.delete_item_success(this.NAME_ITEM_IMAGE).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses() 
                        }else if (res.res==='deleting/image/'+this.VALUE_ITEM_IMAGE+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).title,
                                text:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).text,
                                btnYes:this.MBS.text.delete_item_fail(this.NAME_ITEM_IMAGE).btnYes, 
                            })  
                            this.input.loading = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }


                        if (res.res==='setting/'+this.VALUE_ITEMS+'/successful') { 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_success(this.NAME_ITEMS).title,
                                text:this.MBS.text.update_item_success(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.update_item_success(this.NAME_ITEMS).btnYes,  
                            })  
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()  
                            this.dialog_selected_item = false
                        }else if (res.res==='setting/'+this.VALUE_ITEMS+'/error'){ 
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:this.MBS.text.update_item_fail(this.NAME_ITEMS).title,
                                text:this.MBS.text.update_item_fail(this.NAME_ITEMS).text,
                                btnYes:this.MBS.text.update_item_fail(this.NAME_ITEMS).btnYes, 
                            })  
                            this.input.loading = false
                            this.dialog_selected_item = false
                            this.MBS.actions.progressDialog() 
                            this.MBS.actions.restartResponses()
                        }

                         

                        
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 
